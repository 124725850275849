import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";  
import Button from '@mui/material/Button';  
import { FormControlLabel, Checkbox, Typography } from '@mui/material'; 
 
import TextField from '@mui/material/TextField'; 
import LoadingSpinner from '../LoadingSpinner';  
import FormControl from '@mui/material/FormControl'; 

import {UserLogin, GetSupport, countryCode} from '../../Services/User'
import useToken from '../App/useToken';  
 
import { Notyf } from "notyf";
import ImageCache from '../ImageCache';
import { useTranslation } from 'react-i18next'; 
 
import { MuiTelInput } from 'mui-tel-input'

const notyf = new Notyf({duration: 5000}); 
    
export default function Login() {   
 
    const { t } = useTranslation();
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);  
    const [supportUrl, setSupportUrl] = useState('');
    const [tgUrl, setTgUrl] = useState('');
    const [allowedCountries, setAllowedCountries] = useState(["US"]);
  
    const register = () => { 
        navigate('/register');
    }; 

    const { token, setToken } = useToken();
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        } 
        GetCountryCode();
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetCountryCode = async e => {    
        countryCode({}, CallbackCountryCode);  
    } 
    
    const CallbackCountryCode = (returnData) => {  
        if(returnData.code === 1)
        {  
            var data = returnData.data;  
            setAllowedCountries(data);  
        }   
    }  
    const [username, setUsername] = useState('');   
    const [password, setPassword] = useState('');    
  
    const handleLogin = async e => { 
        e.preventDefault();      
        setLoading(true);  
    
        UserLogin({ 
            username,
            password
        }, CallbackLogin);
    } 
 
    const CallbackLogin = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var token = returnData.data;
            setToken(token.token); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', token.token);  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    }     

    useEffect(() => {
        setIsDisabled(username === '' || password === ''  ); 
    }, [username, password]);

    const handlePhoneChange = (newPhone) => {
        setUsername(newPhone)
    }

    const handleTermsClick = (e) => {
        e.preventDefault();
        navigate('/terms-conditions');
    };

    const handlePrivacyClick = (e) => {
        e.preventDefault();
        navigate('/privacy-policy');
    };
    

    const support = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    };
 
    return( 
        <div className="login-page"> 
            {loading ? <LoadingSpinner /> : ''} 
            
            <div className="header">
                <ImageCache loading="lazy"  src={"/images/logo.png"} alt={t('Login')} width={120} className="home-logo mb-3"/>
                <h4>{t("Login to The VueGo")}</h4>
            </div>
 
            <div className="form-info">   
                <form className="loginBox" onSubmit={handleLogin} >   
               
                    <div className="form-group">   
                        <MuiTelInput
                            required
                            variant="standard"
                            defaultCountry={'US'} // Set the default country (optional)
                            label={t("Phone number & Country")}
                            value={username}
                            onChange={handlePhoneChange}
                            autoComplete="off"
                            onlyCountries={allowedCountries} 
                        />  
                    </div>

                    <div className="form-group">  
                    <FormControl variant="standard">  
                        <TextField   
                        required
                        label={t('Password')}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        id="outlined-adornment-password"
                        type={'password'}
                        onChange={(e)=>inputChangeHandler(setPassword, e)}   
                        /> 
                    </FormControl> 
                    </div>

                    <div className="form-group">  
                        <span className="nav-forgot" onClick={support} style={{ cursor: 'pointer', color: 'blue' }}>
                        Forgot Password?
                        </span>
                    </div>
                    <div className="form-action">  
                        <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Login')}</Button> 
                    </div>   
 
                    <div className="nav-reg">  
                        {t('Don’t have an account?')}<span className="nav-link"  size="large" onClick={register}>{t("Register")}</span> 
                    </div>    

                    <div className="form-group input-terms">  
                    <FormControlLabel
                        required
                        control={<Checkbox />}
                        label={
                            <Typography>
                            By Continuing you are agree to the{' '}
                            <span onClick={handleTermsClick} style={{ cursor: 'pointer', color: 'blue' }}>
                                Terms of Service
                            </span>{' '}
                            and{' '}
                            <span onClick={handlePrivacyClick} style={{ cursor: 'pointer', color: 'blue' }}>
                                Privacy Policy
                            </span>
                            </Typography>
                        }
                    />
                    </div> 
                </form> 
            </div>  
        </div>
        
    );
} 